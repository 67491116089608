import { keyframes } from '@emotion/react';

import { ThemeUITheme } from '../../../components/ThemeUi';
import { underlineStyling } from './links';

export default {
  fontFamily: 'button',
  cursor: 'pointer',
};

export const primary = {
  variant: 'buttons.default',
  display: 'inline-block',
  textTransform: 'uppercase',
  textAlign: 'center',
  fontSize: 'xs',
  letterSpacing: 6,
  fontWeight: 'semiBold',
  borderRadius: 'rounded',
  py: 3,
  px: 5,
  cursor: 'pointer',
  bg: 'buttonBg',
  color: 'buttonColor',
  transition: 'color 0.3s ease-in-out, background-color 0.3s ease-in-out',
  ':hover': {
    color: 'buttonBg',
    bg: 'buttonColor',
  },
};

export const inverted = {
  variant: 'buttons.primary',
  bg: 'buttonColor',
  color: 'buttonBg',
  transition: 'color 0.3s ease-in-out, background-color 0.3s ease-in-out',
  ':hover': {
    color: 'buttonColor',
    bg: 'buttonBg',
  },
};

export const ghost = {
  bg: 'transparent',
  p: 0,
  cursor: 'pointer',
  border: 'none',
};

export const icon = {
  variant: 'buttons.default',
  bg: 'transparent',
  p: 1,
  cursor: 'pointer',
  fontSize: '0px',
  appearance: 'none',
  svg: {
    color: 'primary',
    transition: 'color 0.3s ease-in-out',
  },
  ':hover': {
    svg: {
      color: 'blue3',
    },
  },
};

export const iconMenu = {
  variant: 'buttons.icon',
  p: 0,
};

export const iconGhost = {
  variant: 'buttons.icon',
  boxShadow: 'none',
  borderRadius: 'square',
};

export const slickArrow = {
  variant: 'buttons.icon',
  position: 'absolute',
  top: '100%',
  left: 0,
  mt: 4,
  p: 0,
  svg: {
    color: 'primary',
    width: 35,
    height: 35,
  },
};

/** Not Used on Voom */
export const iconOutline = {
  variant: 'buttons.icon',
};
/** Not Used on Voom */
export const iconPrimary = {
  variant: 'buttons.icon',
};
/** Not Used on Voom */
export const underline = {
  variant: 'buttons.primary',
};

export const outline = {
  variant: 'buttons.primary',
  bg: 'transparent',
  color: 'buttonBg',
  boxShadow: (theme: ThemeUITheme) =>
    `inset 0 0 0 2px ${theme.colors?.buttonBg}`,
  transition: 'box-shadow 0.3s ease-in-out',
  ':hover': {
    boxShadow: (theme: ThemeUITheme) =>
      `inset 0 0 0 4px ${theme.colors?.buttonBg}`,
  },
};

export const navButton = {
  variant: 'links.nav',
  bg: 'transparent',
  p: 0,
  borderRadius: 0,
  '> span': {
    color: 'text',
    whiteSpace: 'nowrap',
    fontWeight: 'semiBold',
    fontSize: 'xs',
    position: 'relative',
    lineHeight: 1.15, // to match the underline on nav link
    pb: 1,
  },
  '&:hover > span::after, &.active  > span::after': {
    ...underlineStyling,
    content: ['none', null, null, '""'],
    bg: 'nav',
    // Underline hover animation
    animation: `${keyframes`
      0% {
        transform-origin: left;
        transform: scaleX(0);
      }

      100% {
        transform-origin: left;
        transform: scaleX(1);
      }
    `} 0.3s ease-in-out`,
  },
  '&.active::after': {
    animation: 'none',
  },
};

/** Not Used on Voom */
export const activeFilter = {
  variant: 'buttons.outline',
};
/** Not Used on Voom */
export const filterItem = {
  variant: 'buttons.outline',
};

/** Not Used on Voom */
export const primarySm = {
  variant: 'buttons.primary',
};

/** Not Used on Voom */
export const outlineSm = {
  variant: 'buttons.outline',
};
