import { keyframes } from '@emotion/react';

export const underlineStyling = {
  content: '""',
  position: 'absolute',
  height: 2,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'block',
};

export default {
  fontFamily: 'link',
  color: 'text',
};

export const inline = {
  variant: 'links.default',
  fontSize: 'xs',
  display: 'block',
  color: 'text',
  textDecoration: 'none',
  my: 2,
  ':hover': {
    textDecoration: 'underline',
  },
};

// TODO: do i need this?
export const ghost = {
  variant: 'buttons.ghost',
  textDecoration: 'none',
};

export const button = {
  variant: 'buttons.primary',
  textDecoration: 'none',
  fontFamily: 'link',
};

export const buttonOutline = {
  variant: 'buttons.outline',
  textDecoration: 'none',
  fontFamily: 'link',
};

export const buttonInverted = {
  variant: 'buttons.inverted',
  textDecoration: 'none',
  fontFamily: 'link',
};

export const underline = {
  variant: 'links.default',
  color: 'link',
  textTransform: 'uppercase',
  textDecoration: 'none',
  fontWeight: 'semiBold',
  fontSize: 'xs',
  letterSpacing: 6,
  position: 'relative',
  pb: 1,
  '::after': {
    ...underlineStyling,
    backgroundColor: 'link',
  },
  '&:hover::after': {
    // Underline hover animation
    animation: `${keyframes`
      0% {
        transform-origin: right;
        transform: scaleX(1);
      }
      40% {
        transform-origin: right;
        transform: scaleX(0);
      }
      60% {
        transform-origin: left;
        transform: scaleX(0);
      }

      100% {
        transform-origin: left;
        transform: scaleX(1);
      }
    `} 0.6s ease-in-out`,
  },
};

// Underline only on hover
export const nav = {
  variant: 'links.default',
  textTransform: 'uppercase',
  textDecoration: 'none',
  height: [null, null, null, 'full'],
  display: ['block', null, null, 'inline-flex'],
  alignItems: 'center',
  letterSpacing: 6,
  '> span': {
    color: 'text',
    whiteSpace: 'nowrap',
    fontWeight: 'semiBold',
    fontSize: 'xs',
    position: 'relative',
    pb: 1,
  },
  '&:hover > span::after, &.active  > span::after': {
    ...underlineStyling,
    content: ['none', null, null, '""'],
    bg: 'nav',
    // Underline hover animation
    animation: `${keyframes`
      0% {
        transform-origin: left;
        transform: scaleX(0);
      }

      100% {
        transform-origin: left;
        transform: scaleX(1);
      }
    `} 0.3s ease-in-out`,
  },
  '&.active::after': {
    animation: 'none',
  },
};

export const icon = {
  variant: 'buttons.icon',
};

export const iconNav = {
  variant: 'buttons.icon',
};

export const iconMenu = {
  variant: 'buttons.iconMenu',
};

export const iconGhost = {
  variant: 'buttons.iconGhost',
};

export const legal = {
  variant: 'links.default',
  fontSize: 'inherit',
  display: 'inline-block',
  color: 'text',
  textDecoration: 'underline',
  transition: 'color 0.3s ease-in-out',
  ':hover': {
    color: 'primary',
  },
};

export const breadCrumb = {
  textTransform: 'uppercase',
  display: 'inline-block',
  color: 'darkGrey',
  fontSize: 'copyright',
  letterSpacing: 7,
  lineHeight: 1,
  fontWeight: 'semiBold',
};

/** Not used on Voom */
export const link = {
  variant: 'links.underline',
};

/** Not used on Voom */
export const bold = {
  variant: 'links.underline',
  fontWeight: 'semibold',
};
/** Not used on Voom */
export const footer = {
  variant: 'links.nav',
  fontWeight: 'regular',
};
/** Not used on Voom */
export const footerBold = {
  variant: 'links.nav',
};

/** Not used on Voom */
export const iconNavWithIcon = {
  variant: 'buttons.iconMenu',
};

/** Not used on Voom */
export const buttonSm = {
  variant: 'buttons.primarySm',
};
/** Not used on Voom */
export const buttonOutlineSm = {
  variant: 'buttons.primarySm',
};
