import { useSelect } from 'downshift';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import {
  DeepMap,
  FieldError,
  FieldValues,
  type UseFormSetValue,
} from 'react-hook-form';

import {
  Box,
  Flex,
  Label,
  Link,
  Text,
  ThemeUIStyleObject,
  ThemeUITheme,
  useThemeUI,
} from '../components';
import { ChevronSvg } from '../svg/icons';

export const underlineStyling = {
  content: '""',
  position: 'absolute',
  height: 2,
  left: 0,
  top: 'calc(100% + 2px)',
  right: 0,
  display: 'block',
  borderRadius: 'radius',
};

interface FormSelectProps {
  label: string;
  type?: string;
  register: { name: string };
  errors?: DeepMap<FieldValues, FieldError>;
  sx?: ThemeUIStyleObject;
  className?: string;
  options: string[];
  setValue: UseFormSetValue<FieldValues>;
  selectedItem?: string | null;
  onSelectedItemChange?: (selectedItem: string) => void;
}

export const FormSelect = ({
  label,
  register,
  errors,
  sx,
  className,
  options,
  setValue,
  selectedItem: initialSelectedItem,
  onSelectedItemChange,
}: FormSelectProps) => {
  const [inputActive, setInputActive] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState(initialSelectedItem || null);
  const isVoomTheme = process.env.THEME === 'voom';

  const { theme } = useThemeUI();

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    closeMenu,
  } = useSelect({
    items: options,
    selectedItem,
    onSelectedItemChange: ({ selectedItem }) => {
      setSelectedItem(selectedItem);
      if (onSelectedItemChange) {
        onSelectedItemChange(selectedItem);
      }
    },
  });

  const active = selectedItem;

  const focused = inputActive && !isOpen;

  const defaultInputStyles = {
    width: 'full',
    fontFamily: 'body',
    bg: 'white',
    color: 'black',
    borderRadius: isVoomTheme ? 'square' : 'radius',
    border: 'none',
    boxShadow: '0 0 6px 0 rgba(0,0,0,0.2)',
    height: '53px',
    outline: (theme: ThemeUITheme) =>
      errors
        ? `${theme.colors?.red} solid 2px`
        : focused
          ? `${theme.colors?.primary} solid 2px`
          : isVoomTheme
            ? `${theme.colors?.lightGrey} solid 2px`
            : 'none',
  };

  const handleBlur = () => {
    setInputActive(false);
    closeMenu();
  };

  useEffect(() => {
    setValue(register.name, selectedItem, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [selectedItem, register.name, setValue]);

  return (
    <Box sx={{ position: 'relative', mb: 3, ...sx }} className={className}>
      <div
        sx={{
          ...defaultInputStyles,
          borderBottomRightRadius: isOpen ? '0' : 'radius',
          borderBottomLeftRadius: isOpen ? '0' : 'radius',
          minHeight: '53px',
        }}
      >
        <Flex
          sx={{
            padding: 1,
            cursor: 'pointer',
            justifyContent: 'space-between',
            height: 'full',
          }}
          {...register}
          {...getToggleButtonProps()}
          onFocus={() => setInputActive(true)}
          onBlur={handleBlur}
        >
          <Flex sx={{ flexDirection: 'column' }}>
            <Label sx={{ display: 'block' }} {...getLabelProps()}>
              <motion.span
                animate={active ? 'active' : 'default'}
                initial={false}
                variants={{
                  active: {
                    x: theme.space?.[3],
                    y: isVoomTheme ? theme.space?.[1] : theme.space?.[2],
                    color: isVoomTheme
                      ? (theme?.colors?.grey as string)
                      : (theme?.colors?.grey400 as string),
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    fontSize: `${theme?.fontSizes?.copyright}px`,
                  },
                  default: {
                    x: 20,
                    y: 19,
                    color: isVoomTheme
                      ? (theme?.colors?.darkGrey as string)
                      : (theme?.colors?.black as string),
                  },
                }}
                transition={{ duration: 0.15 }}
                sx={{
                  display: 'inline-block',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  pointerEvents: 'none',
                  lineHeight: 1,
                  fontFamily: 'body',
                  fontWeight: 'medium',
                  fontSize: ['copyright', 'xs', null, 'sm'],
                }}
              >
                {label}
              </motion.span>
            </Label>
            <div
              sx={{
                ml: '12px',
                mt: 4,
                fontSize: ['copyright', 'xs', null, 'sm'],
                fontWeight: 'medium',
              }}
            >
              {selectedItem}
            </div>
          </Flex>
          {isOpen ? (
            <ChevronSvg
              sx={{
                width: 16,
                mx: 2,
                mt: 1,
                transform: 'rotate(180deg)',
              }}
            />
          ) : (
            <ChevronSvg
              sx={{
                width: 16,
                mx: 2,
                mt: 1,
              }}
            />
          )}
        </Flex>
        <ul
          {...getMenuProps()}
          sx={{
            listStyle: 'none',
            width: '100%',
            boxShadow: 'formSelectShadow',
            borderRadius: 'radius',
            borderTopRightRadius: isOpen ? '0' : 'radius',
            borderTopLeftRadius: isOpen ? '0' : 'radius',
            position: 'absolute',
            bg: 'white',
            cursor: 'pointer',
            zIndex: 'sticky',
            overflow: 'hidden',
            span: {
              fontSize: ['copyright', 'xs', null, 'sm'],
              fontWeight: 'medium',
            },
          }}
        >
          {isOpen &&
            options.map((item, index) => (
              <li
                sx={{
                  p: 2,
                  ml: 2,
                }}
                key={item}
                {...getItemProps({
                  item,
                  index,
                })}
              >
                <Link
                  variant="footer"
                  sx={{ color: selectedItem === item ? 'primary' : 'black' }}
                >
                  {item}
                </Link>
              </li>
            ))}
        </ul>
      </div>
      <input type="hidden" {...register} />

      {errors && (
        <Box sx={{ position: 'absolute', top: 'calc(100% - 3px)', left: 0 }}>
          {errors.type === 'required' ? (
            <Text sx={{ color: 'red', fontSize: 'copyright' }}>Required</Text>
          ) : errors.type === 'email' ? (
            <Text sx={{ color: 'red', fontSize: 'copyright' }}>Email</Text>
          ) : (
            <Text sx={{ color: 'red', fontSize: 'copyright' }}>Error</Text>
          )}
        </Box>
      )}
    </Box>
  );
};
