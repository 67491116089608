import { useLocation } from '@reach/router';

import { Flex, Link, StyledSup, Text, ThemeUIStyleObject } from '../components';

interface BreadCrumbsProps {
  links?: LinkProps[];
  sx?: ThemeUIStyleObject;
  className?: string;
}

interface LinkProps {
  url: string;
  label: string;
}

const getLinks = (pathname: string): LinkProps[] => {
  const uniquePathNames = [...new Set(pathname.split('/'))];
  const addSlash = uniquePathNames.map((str) => `/${str}`);
  let prevLink = '';
  return addSlash.map((str: string) => {
    prevLink += str;
    const label = str
      .replace('/', '')
      .replace(/-/g, ' ')
      .replace('bunionplasty', 'bunionplasty®');
    const url = `${prevLink}/`.replace(/\/\//g, '/');

    return {
      url: url,
      label: label,
    };
  });
};

export const BreadCrumbs = ({
  links,
  sx,
  className,
}: BreadCrumbsProps): JSX.Element => {
  const { pathname } = useLocation();
  const crumbLinks = links ?? getLinks(pathname);

  return (
    <Flex
      className={className}
      as={'ul'}
      sx={{
        listStyle: 'none',
        px: 0,
        my: 2,
        flexWrap: 'wrap',
        ...sx,
      }}
    >
      {crumbLinks.map(({ url, label }, i) => {
        // dont show home link
        if (!links && i === 0) return;
        const lastLink = crumbLinks.length === i + 1;
        return (
          <Flex
            as={'li'}
            key={label}
            sx={{ alignItems: 'center', flex: '0 0 auto' }}
          >
            {lastLink ? (
              <Text
                sx={{
                  variant: 'links.breadCrumb',
                  color: 'black',
                  textDecoration: 'none',
                  fontWeight: 'regular',
                  pt: 1,
                }}
              >
                <StyledSup>{label}</StyledSup>
              </Text>
            ) : (
              <>
                <Link variant="breadCrumb" to={url}>
                  <StyledSup>{label}</StyledSup>
                </Link>
                <Text sx={{ mx: 2 }}>{`>`}</Text>
              </>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default BreadCrumbs;
